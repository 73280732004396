import { applyMiddleware } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './reducers';

const middleware = [thunk];

const store = configureStore({
	reducer: rootReducer,
	devTools: composeWithDevTools(applyMiddleware(...middleware)),
});

export default store;
